<template>
    <v-container fluid fill-height app class="d-flex align-start">
        <v-sheet height="100%" width="100%" class="rounded-lg">
            <v-toolbar flat class="rounded-lg">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary">
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <div class="d-flex flex-column align-start">
                    <span class="text-h6 font-weight-regular"> {{ tableName }}</span>
                    <!-- <div class="mb-2 d-flex align-center">
                        <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>
                        <span class="text-caption">Forneça uma descrição para esta tabela.</span>
                    </div> -->
                </div>

                <v-spacer></v-spacer>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary" dark
                            v-on="on"
                            class="mb-2 pa-0"
                            min-width="48px"
                            @click="$router.push('/')"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mx-4 mb-2"></v-divider>

            <v-container fluid>
                <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0">
                        <v-autocomplete
                            v-model="selectedEvent"
                            label="Evento"
                            :items="events"
                            item-text="text"
                            :loading="fetchingEvents"
                            clearable
                            return-object
                            @click:clear="consolidationProcess = []"
                            @input="getConsolidationStatus($event)"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0 d-flex justify-end">
                        <v-btn color="success" v-if="permission"
                            :disabled="disableConsolidate"
                            @click="consolidate()"
                            :loading="consolidating"
                        >
                            {{ !unconsolidated && consolidationProcess.length ? 'Reprocessar consolidação' : 'Consolidar' }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-container v-if="!selectedEvent"
                    fluid class="d-flex flex-column align-center justify-center mt-12"
                >
                    <p class="mb-6">Selecione um evento para iniciar a consolidação</p>
                </v-container>

                <v-container v-else-if="consolidating"
                    fluid class="d-flex flex-column align-center justify-center mt-12"
                >
                    <p class="mb-6">Iniciando consolidação...</p>
                    <v-progress-linear
                        color="primary"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-container>

                <v-container v-else-if="fetchingConsolidationStatus"
                    fluid class="d-flex flex-column align-center justify-center mt-12"
                >
                    <p class="mb-6">Verificando status da consolidação...</p>
                    <v-progress-linear
                        color="primary"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-container>

                <v-container v-else-if="selectedEvent && !consolidationProcess.length && !fetchingConsolidationStatus && !consolidating"
                    fluid class="d-flex flex-column align-center justify-center mt-12"
                >
                    <p class="mb-6">Evento não consolidado</p>
                </v-container>

                <v-fade-transition hide-on-leave>
                    <v-container fluid class="ma-0 mt-12 pa-0" v-if="selectedEvent && consolidationProcess.length">
                        <v-row no-gutters class="ma-0 pa-0 d-flex flex-column">
                            <v-col class="mb-2">
                                <span class="mr-2">Consolidado por:</span>
                                <span class="text-h6">{{ consolidationProcess[0].name }}</span>
                            </v-col>
                            <v-col>
                                <v-progress-linear
                                    :value="consolidationProcess[consolidationProcess.length - 1].process_percent"
                                    color="primary"
                                    height="36"
                                    class="rounded-lg"
                                >
                                    <strong>{{ Math.ceil(consolidationProcess[consolidationProcess.length - 1].process_percent) }}%</strong>
                                </v-progress-linear>
                            </v-col>
                        </v-row>

                        <template v-for="process in consolidationProcess">
                            <v-row no-gutters class="ma-0 pa-0 mt-2" :key="process.id_pl_consolidation_status">
                                <v-col>
                                    <span class="text-caption mr-2">{{ `${formatDate(process.process_start)}:` }}</span>
                                    <span>{{ process.status_message }}</span>
                                </v-col>
                            </v-row>
                        </template>
                    </v-container>
                </v-fade-transition>
            </v-container>
        </v-sheet>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'Consolidation',

    components: { },

    computed: {
        ...mapGetters('auth', ['getMenu', 'getPermissions']),

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        disableConsolidate() {
            let disabled = false

            if (!this.selectedEvent || this.fetchingConsolidationStatus) {
                disabled = true
            }

            return disabled
        }
    },

    data() {
        return {
            events: [],
            selectedEvent: null,
            fetchingEvents: false,
            fetchingConsolidationStatus: false,
            consolidating: false,
            consolidationProcess: [],
            unconsolidated: false,

            permission: true,
        }
    },

    created() {
        const permission = `CONSOLIDATE_PL`
        const foundPermission = this.getPermissions.find(p => p === permission)
        if (!foundPermission) {
            this.permission = false
        }

        this.getEvents()
    },

    methods: {
        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        async getEvents() {
            this.fetchingEvents = true

            const filter = {
                orderColumn: 'description',
                textColumn: 'description',
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 14
                    },
                    {
                        AndOr: 'AND',
                        column: 'id_status',
                        operator: '=',
                        value: 2
                    }
                ]
            }
            
            try {
                const res = await this.$http.post(this.$ipEvent + 'event/list-options', { filter })
                if (res) {
                    this.events = res.data.rows
                    this.fetchingEvents = false
                }
            } catch (err) {
                this.fetchingEvents = false
                this.$fnError(err)
            }
        },

        async getConsolidationStatus(e) {
            this.consolidationProcess = []
            this.unconsolidated = false

            if (e) {
                this.fetchingConsolidationStatus = true
                
                let filter = {
                    conditions: [
                        {
                            "AndOr": "AND",
                            "column": "id_event",
                            "operator": "=",
                            "value": e.id
                        }
                    ]
                }

                try {
                    let status = await this.$http.post(this.$ipPlPlanning + 'pl-consolidation-status/list', { filter })
                    if (status) {
                        this.fetchingConsolidationStatus = false

                        if (status.data.rows.length) {
                            this.consolidationProcess = status.data.rows

                            if (parseInt(this.consolidationProcess[this.consolidationProcess.length - 1].process_percent, 10) < 100) {
                                this.updateConsolidationStatus(this.selectedEvent)
                            }
                        } else {
                            this.unconsolidated = true
                        }
                    }
                } catch (err) {
                    this.fetchingConsolidationStatus = false
                    this.$fnError(err)
                }
            }
        },

        async updateConsolidationStatus(e) {
            if (e) {
                let filter = {
                    conditions: [
                        {
                            "AndOr": "AND",
                            "column": "id_event",
                            "operator": "=",
                            "value": e.id
                        }
                    ]
                }

                try {
                    let status = await this.$http.post(this.$ipPlPlanning + 'pl-consolidation-status/list', { filter })
                    if (status) {
                        if (status.data.rows[status.data.rows.length - 1].process_end) {
                            this.consolidationProcess.push(status.data.rows[status.data.rows.length - 1])
                            this.$toast.success('Consolidação finalizada com sucesso!')
                        } else if (status.data.rows.length === this.consolidationProcess.length) {
                            this.updateConsolidationStatus(this.selectedEvent)
                        } else {
                            for (let i = this.consolidationProcess.length; i < status.data.rows.length; i++) {
                                this.consolidationProcess.push(status.data.rows[i])
                                this.updateConsolidationStatus(this.selectedEvent)
                            }
                        }
                    }
                } catch (err) {
                    this.$fnError(err)
                }

                
            }
        },

        async consolidate() {
            this.consolidating = true
            this.consolidationProcess = []
            this.unconsolidated = false

            let payload = {
                id_event: this.selectedEvent.id
            }

            try {
                let res = await this.$http.post(this.$ipPlPlanning + 'pl-planning/consolidation', { ...payload })
                if (res) {
                    this.getConsolidationStatus(this.selectedEvent)
                    this.consolidating = false
                }
            } catch (err) {
                this.consolidating = false
                this.$fnError(err)
            }
        },

        formatDate(date) {
            moment.locale('pt-br')
            return moment(date).format('DD/MM/YYYY HH:mm:ss')
        },
    },
}
</script>

<style>
</style>